import { Skeleton, Button, Flex, Box, useToast} from "@chakra-ui/react";
import { useProduct } from "../../providers/ProductProvider";
import { check } from "../../helper/Svg";
import { VariationsSize } from "../../models/ProductModel";
import { useShoppingCart } from "../../providers/ShoppingCartProvider";
import { MessageModel } from "../../models/MessageModel";
import { ClarityModule } from "../../helper/Clarity";


export const ProductSize = () => {

  const prod = useProduct();
  const shop = useShoppingCart();
  const toast = useToast();

  if (!prod?.initialized ) return (
    <Skeleton w={"100%"} m={"15px"}>
      <Box
        w={"100%"}
        py={5}
        fontSize="18px"
        color="purple.700"
        fontWeight="500"
        textAlign={"center"}
      >
        Lo sentimos, producto no disponible.
      </Box>
    </Skeleton>
    
  )

  if (!prod?.loading && prod?.product == null) return (
   <Box
    py={5}
    fontSize="18px"
    color="purple.700"
    fontWeight="500"
    textAlign={"center"}
   >
    Lo sentimos, producto no disponible.
   </Box>
  );

  const deleteSize = async(size: VariationsSize) => {
    await shop?.delShoppingCart(size.Upc);
    const newCart = await shop?.getShoppingCart();
    await prod?.fetchProduct(newCart);
  }

  const validateCartLimit = async(): Promise<MessageModel| true>  => {
    let newCart = await shop?.getShoppingCart();
    if (newCart && newCart?.length >= 3) {
      return {
        code: 400,
        type: "error",
        title: "Llegaste al limite de productos en tu probador.",
        message: "Selecciona hasta 3 tallas o productos diferentes."
      }
    }
    return true;
  }
  const selectSize = async(size: VariationsSize): Promise<MessageModel> => {
      if (prod?.product == null) {
        return {
          code: 400,
          type: "error",
          title: "Fuera del area de servicio.",
          message: "Intente con otra talla o producto."
        };
      }
      prod?.setShowReturn(prod?.product?.productId !== prod?.visitedProduct?.productId);
      let result = await shop?.addShoppingCart(
        prod?.product,
        size.TN,
        size.Upc,
        prod?.colors[0].colorName,
        size.sizeName
      );
      let newCart = await shop?.getShoppingCart();
      await prod?.fetchProduct(newCart);
      document.getElementById('trycloud-area')?.scrollIntoView({behavior: 'smooth'});
      if (result?.length == 0) {
        return {
          code: 400,
          type: "error",
          title: "Fuera del area de servicio.",
          message: "Intente con otra talla o producto."
        }
      }
      ClarityModule.funnelAddToCart();
      return {
        code: 200,
        type: "success",
        title: "Se agrego correctamente al carrito.",
        message: "Ahora puedes verlo en tu carrito de compras."
      }
  }

  const showRelatedProducts = async(size: VariationsSize): Promise<true> => {
      const productSelected = {
        productId: prod?.product?.productId,
        colorName: prod?.colors[0].colorName ?? "",
        sizeName: size.sizeName,
        image: prod?.colors[0].image ?? "",
      };
      prod?.setShowReturn(false);
      prod?.setProductOutStock(productSelected);
      await prod?.fetchProductsRelated(size.sizeName, size.TN, size.Upc);
      ClarityModule.funnelSizeNotAvailable();
      return true;
  }

  const btnHandlerVariation = async(size: VariationsSize) => {
    try{
      prod?.setLoading(true);
      let result;
      if (size.isSelected){
        await deleteSize(size);
      } else /* if (size.isAvailable) */ {
        result = await validateCartLimit();
        if (result === true) {
          result = await selectSize(size);
          if (result != null && result.code !== 200) {
            await showRelatedProducts(size);
          }
        }
      }
      if (result != null) {
        toast({
          title: result.title,
          description: result.message,
          status: result.type === "error" ? "warning" : "success",
          duration: 3000,
          isClosable: true,
          containerStyle: {
            border: '20px solid red',
          },
        })
      }
    } finally {
      prod?.setLoading(false);
    }
  }

  return (
    <Skeleton w={"100%"} mt={prod?.loading ? "15px" : "0px"} isLoaded={!prod?.loading}>
      <Box
        id="size-trycloud"
        fontSize="19px"
        color="purple.700"
        fontWeight="700"
        textAlign={"left"}
        pb={5}
      >
        Selecciona hasta 3 tallas
      </Box>
      <Flex  alignItems="center" justifyContent="center" px={5}>
        <Flex w="100%" justifyContent="center" wrap="wrap" gap={2}  px={5}>
          {prod?.sizes?.map((size) => {
            let variationId = size.TN;
            return (
              <Skeleton
                h="30px"
                w="100px"
                isLoaded={!prod?.loading}
                rounded="5px"
                key={variationId}
              >
                <Button
                  h="30px"
                  w="100px"
                  fontSize="15px"
                  fontWeight="bold"
                  style={{ 
                    background: size.isSelected ? "#553C9A" : "white",
                    border: "1px solid #553C9A",
                    color: size.isSelected ? "white" : "#553C9A",
                    borderRadius: "4px",
                  }}
                  //color={ size.isSelected ? "white" : "purple.700" }
                  onClick={() => btnHandlerVariation(size)}
                  //_hover={{backgroundColor:"#551C90", color:"white"}}
                >
                  {`${size.sizeName}`}
                  {(size.isSelected) && check }
                </Button>
              </Skeleton>
            );
          })}
        </Flex>
      </Flex>
    </Skeleton>
  );
};
