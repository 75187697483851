import { useContext, useEffect, useState } from "react";
import { AuthService } from "../services/AuthService.ts";
import { useOrder } from "../hooks/useOrder.ts";
import { useDataConfig } from "../hooks/useDataConfig.ts";
import { hiddenElements, showElement } from "../helper/Render.ts";
import { WidgetContext, WidgetContextType } from "../context/WidgetContext.tsx";
import { SentryApi } from "../helper/Sentry.ts";
import { dataConfig } from "../helper/Config.ts";
import { DataConfigModel } from "../models/DataConfigModel.ts";
import { ClarityModule } from "../helper/Clarity.ts";

/**
 *  It sets up a state for the widget,
 *  manages the widget's behavior based on the user's authentication status,
 *  and provides functions to handle the widget's state and steps.
 *  It also provides context values for the widget's state and actions.
 *
 * @param {{ children: React.JSX.Element; }} children - The child elements to be rendered within the component.
 * @return {React.JSX.Element} The rendered WidgetContext.Provider component.
 */
export const WidgetProvider = ({
  children,
  configData,
  shadowHostRef,
}: {
  children: React.JSX.Element;
  configData: DataConfigModel | null;
  shadowHostRef: React.RefObject<HTMLDivElement>;
}): React.JSX.Element => {
  const [isOn, setIsOn] = useState<boolean>(false);
  const [isOpenModalAdress, setIsOpenModalAdress] = useState<boolean>(false);
  const [isOpenModalHowTowork, setIsOpenModalHowTowork] = useState<boolean>(false);
  const [isOpenModalAskForAddress, setIsOpenModalAskForAddress] = useState<boolean>(false);
  const { getOrder } = useOrder();
  const [step, setStep] = useState<string>("product");
  const { startShowFirstTimeModal, setDataWidgetOpen } = useDataConfig();

  useEffect(() => {
    const tryCloud = localStorage.getItem("tryCloud") as string;
    if (tryCloud === "true") {
      if (AuthService.validateSession()) {
        openWidget();
      } else {
        setIsOn(false);
        showElement();
        setIsOpenModalAskForAddress(true);
        ClarityModule.funnelModalTimer();
      }
    } else {
      setIsOn(false);
      showElement();
      //startShowFirstTimeModal(setIsOpenModalAskForAddress, configData);
      // NOTE: Se inabilita para que no se muestre el modal de la primera vez en todas las marcas.
      startShowFirstTimeModal(setIsOpenModalAskForAddress, null);
    }
    ClarityModule.initialize();
  }, []);

  /**
   * Validate if the order is in progress and handle the steps accordingly.
   * @return {Promise<boolean>} This function does not return anything.
   */
  const validateOrderInProgress = async (): Promise<void> => {
    const orderResp = await getOrder();
    if (orderResp) {
      handlerSteps("finish");
    }
  };

  /** Function to close the widget and perform various actions like setting state, local storage, and sending metrics.
   * @return {Promise<void>} No return value
   */
  const closeWidget = async (): Promise<void> => {
    setIsOn(false);
    showElement();
    localStorage.setItem("tryCloud", "false");
    handlerSteps("product");
    ClarityModule.activate();
    SentryApi.captureMessage(`${dataConfig.metricsPrefix}: Inactivated`);
  };

  /** Opens the widget.
   * @return {Promise<void>}
   */
  const openWidget = async (): Promise<void> => {
    handlerSteps("product");
    await validateOrderInProgress();
    setIsOn(true);
    hiddenElements();
    localStorage.setItem("tryCloud", "true");
    setDataWidgetOpen();
  };

  /**
   * Function to open or close the modal for address.
   * @param {boolean} state - the state to set for opening or closing the modal
   * @return {void}
   */
  const openCloseModalAddress = (state: boolean): void => {
    setIsOpenModalAdress(state);
    if (state == true) {
      ClarityModule.funnelModalAdress();
    }
  };

  /**
   * A function to open or close the "How To Work" modal.
   * @param {boolean} state - The new state for the modal (true for open, false for close).
   * @return {void}
   */
  const openCloseModalHowTowork = (state: boolean): void => {
    setIsOpenModalHowTowork(state);
  };

  const openCloseModalAskForAddress = (state: boolean): void => {
    setIsOpenModalAskForAddress(state);
  };

  /**
   * Sets the next step for the widget.
   * @param {('body' | 'phone' | 'data-customer' | 'finish' | 'howToWork')} nextStep - The next step for the widget.
   * @return {void}
   */
  const handlerSteps = (
    nextStep:
      | "product"
      | "phone"
      | "address"
      | "data-customer"
      | "finish"
      | "howToWork"
  ): void => {
    setStep(nextStep);
    funnel(nextStep);
  };

  const funnel = (nextStep:
    | "product"
    | "phone"
    | "address"
    | "data-customer"
    | "finish"
    | "howToWork"): void => {
    switch (nextStep) {
      case 'product':
        if (!AuthService.validateSession()){
          ClarityModule.funnelAnonimo();
        }
        break;
      case 'phone':
        ClarityModule.funnelOTP();
        break;
      case 'address':
        ClarityModule.funnelCheckout();
        break;
      case 'data-customer':
        ClarityModule.funnelConfirmation();
        break;
      case 'finish':
        ClarityModule.funnelSuccess();
        break;
      default:
        break;
    }
  };

  const widgProp: WidgetContextType = {
    isOn,
    step,
    isOpenModalAdress,
    isOpenModalHowTowork,
    isOpenModalAskForAddress,
    configData,
    shadowHostRef,
    openCloseModalAddress,
    openCloseModalHowTowork,
    openCloseModalAskForAddress,
    closeWidget,
    openWidget,
    handlerSteps,
    setIsOn,
  };

  return (
    <WidgetContext.Provider value={widgProp}>{children}</WidgetContext.Provider>
  );
};

/**
 * Returns the widget from the widget context.
 * @return {WidgetContextType | null} The widget from the widget context
 */
export const useWidget = (): WidgetContextType | null => {
  const widget: WidgetContextType | null = useContext(WidgetContext);
  return widget;
};
