import {
  Flex,
  Text,
  Button,
  Center,
  Image,
  Box,
  Link,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useModaladdress2 } from "../../hooks/useModaladdress2";
import { useWidget } from "../../providers/WidgetProvider";
import { useAddress } from "../../providers/AddressProvider";
import { AddressModel } from "../../models/AddressModel";
import { Data } from "../../helper/Data";

export const ModalAddress2 = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const auntoCompleteRef = useRef<HTMLInputElement>(null);
  const aditionalInfoRef = useRef<HTMLInputElement>(null);
  const widget = useWidget();
  const addr = useAddress();
  const toast = useToast();
  if (widget == null) return <></>;
  const {
    buttonSaveActive,
    styleInputMap,
    country,
    locality,
    formatAddress,
    latitude,
    longitude,
    additionalInfo,
    iniMap,
    handlerAdditionalInformation,
  } = useModaladdress2();

  useEffect(() => {
    iniMap(mapRef.current, auntoCompleteRef.current, aditionalInfoRef.current);
  }, []);

  /** A function that handles saving an address.
   * @return {Promise<void>} a promise with the result of saving the address
   */
  const handlerSaveAddress = async (): Promise<void> => {
      if (formatAddress == "" || additionalInfo == "") {
          toast({
            title: "Disculpe.",
            description: "Complete los datos de la dirección para continuar.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          return;
      }
      const newAddress: AddressModel = {
        id: 0,
        customer_id: "",
        country_name: country,
        city_name: locality,
        address: formatAddress,
        additional_info: additionalInfo,
        latitud: latitude,
        longitud: longitude,
        place_Id: "--",
        commission: 0,
      };
      const result = await addr?.changeAddress(newAddress);
      if (result?.code === 200) {
        widget?.openCloseModalAddress(false);
      }
      toast({
        title: result?.title,
        description: result?.message,
        status: result?.type,
        duration: 9000,
        isClosable: true,
      });
  };

  return (
    <Box
      flexDir="column"
      alignItems="center"
      //justifyContent={Data.getHostId() !== '279038' ? "center" : ""}//Newbalance
      justifyContent="center"
      position={Data.getHostId() !== '279038' ? "fixed" : "sticky"}//Newbalance
      top="0"
      left="0"
      right="0"
      bottom="0"
      w="100%"
      h="100%"
      bg={Data.getHostId() !== '279038' ? "rgba(0, 0, 0, 0.5)" : "#ffffff"}
      zIndex="1000"
      display={!widget.isOpenModalAdress ? "none" : "flex"}
    >
      <Flex
        direction="column"
        alignItems="center"
        bg="white"
        border={Data.getHostId() !== '279038' ?  "1px solid #ddd": "1px solid #ddd"}
        shadow={Data.getHostId() !== '279038' ? "none" : "md"}
        overflowY="auto"
        borderRadius={Data.getHostId() !== '279038' ? "15px" : "1px"}
        w={Data.getHostId() !== '279038' ? "90%": "100%"}
        maxW={Data.getHostId() !== '279038' ? "340px" : "90%"}
        p="1rem"
        gap="0.4rem"
        display={!widget.isOpenModalAdress ? "none" : "flex"}
      >
        <Flex direction="row" justifyContent="space-between" w="100%">
          <Center w="100%">
            <Image
              boxSize="50px"
              objectFit="cover"
              opacity={1}
              src="https://trycloud.s3.us-east-2.amazonaws.com/assets/images/TryCloud-logo-sqr-small.png"
              alt="trycloud logo"
            />
          </Center>
          <Link
            color="purple.700"
            fontSize="25px"
            fontWeight="bold"
            textDecoration="none"
            _hover={{ textDecoration: "none" }}
            onClick={() => widget.openCloseModalAddress(false)}
          >
            x
          </Link>
        </Flex>

        <Text fontWeight="bold" fontSize="26px" textAlign="center">
          Dime dónde estás
        </Text>
        <Text textAlign="center" w={Data.getHostId() !== '279038' ? "70%" : "90%"} margin="auto">
          Para validar disponibilidad del servicio en tu zona
        </Text>
        <input
          type="text"
          id="place_input"
          ref={auntoCompleteRef}
          placeholder="Dirección o punto de referencia"
          style={styleInputMap}
        />
        {/* <div id="map" ref={mapRef} style={{ width: Data.getHostId() !== '279038' ? "260px": "90%", minHeight: Data.getHostId() !== '279038' ? "115px" : "200px" }}></div> */}
        <div id="map" ref={mapRef} style={{ width: "260px", minHeight: "115px" }}></div>
        <input
          id="additional-info-dato"
          ref={aditionalInfoRef}
          type="text"
          name="aditionalInfoInfo"
          placeholder="#casa o apartamento"
          style={styleInputMap}
          onChange={handlerAdditionalInformation}
        />
        <Button
          onClick={handlerSaveAddress}
          w= {Data.getHostId() !== '279038' ? "260px":"90%"}
          //bg="purple.700"
          //color="white"
          style={{ 
            background: "#553C9A",
            color: "white",
          }}
          isDisabled={!buttonSaveActive}
          isLoading={addr?.isLoading}
          loadingText="Espere un momento"
          _hover={{ bg: "purple.400" }}
        >
          Continuar
        </Button>
      </Flex>
    </Box>
  );
};
