//import { dataConfig } from "../../helper/Config";
//import { SentryApi } from "../../helper/Sentry";
import { DataConfigModel } from "../../models/DataConfigModel";
import { Brands } from "./Brands";

export class SteveMadden extends Brands {

    public modeTest: boolean = false;

    public startApp = (execute: (configResponse:DataConfigModel | null) => void, configResponse:DataConfigModel | null) => {
        if (!this.allowRender()) return;
        execute(configResponse);
    } 
  
    public getArea = () : HTMLElement | null | undefined => {
        if (this.hasdiscountElement() &&
            (localStorage.getItem("tryCloud") === "false" || localStorage.getItem("tryCloud") === null)) return null;
        const elementParent = document.querySelector("#add-item-form > div.product-options > div.select.clearfix");
        if (elementParent) {
            const elementBefore = document.querySelector("#add-item-form > div.product-options > div.select.clearfix > div.swatch.clearfix.select_size");
            const trucloudArea = document.createElement("div");
            trucloudArea.setAttribute("id", "trycloud-area");
            trucloudArea.setAttribute("name", "trycloud-area");
            if (!elementBefore) return null;
            elementParent?.insertBefore(trucloudArea, elementBefore.nextSibling);
            return trucloudArea;
        }
        //SentryApi.captureException(new Error(`${dataConfig.metricsPrefix}: No se encontro el elemento necesario para renderizar el widget`));
        console.log("No se encontro el elemento necesario para renderizar el widget");
        return null;
    }

    public hasdiscountElement = () : Element | null  => {
        const discountElement = document.querySelector("#price-preview > del");
        return discountElement;
    }
    public selectElement = () => { 
        let elements = [];
        elements.push(document.querySelector("#add-item-form > div.product-options > div.select.clearfix > div.swatch.clearfix.select_size"));
        elements.push(document.querySelector("#addi-product-widget_y97u3bd8r"));
        elements.push(document.querySelector('#add-item-form > div.product-options > div.cutsom_button'));
       return elements;
    }

    public hiddenElements = () => {
        let elements = this.selectElement();
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element) {
                element.style.cssText = "display:none";
            }
        }
        this.additionalStyles();
    }

    public showElement = () => {
        let elements = this.selectElement();
        for (let index = 0; index < elements.length; index++) {
            const element = elements[index] as HTMLElement;
            if (element) {
                element.style.cssText = "";
            }
        }
        this.additionalStyles();
    }

    public additionalStyles = () => {
       
        let selectElement = document.querySelector("#add-item-form > div.desktopOnly > h1") as HTMLElement;
        if (selectElement){
            selectElement.style.cssText = "font-weight: 700;";
        }
       
    }

    /**
     * Return whether to render the widget or not, based on the data on the URL.
     * @return {boolean} Whether to render the widget or not
     * */
    public allowRender = (): boolean => {
        if (window.location.pathname.indexOf('/products/') > -1){
            return true;
        }
        return false;
    }

     /**
     * Takes the product id from element 'productData' and return it
     * @returns {string} the product id.
     */
     public getProductId = (): string => {
        const scriptElement = document.querySelector("#price-preview");
        const dataId = scriptElement?.getAttribute("data-id");
        return dataId ?? "";
    }
}