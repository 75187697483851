export const outsiteStyle = `  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
      html, body {
        font-family: 'Inter', 
        sans-serif; 
        margin: 0; 
        padding: 0; 
        box-sizing: border-box; 
    } 
      
      div[id^="toast-"][data-status="success"]:not([id$="-title"]):not([id$="-description"]) {
        color-scheme: light;
        font-feature-settings: "kern";
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        line-height: 1.5;
        pointer-events: auto;
        border-width: 0;
        border-style: solid;
        box-sizing: border-box;
        word-wrap: break-word;
        border-color: #E2E8F0;;
        display: flex;
        position: relative;
        overflow: hidden;
        background: #2F855A;
        padding-inline-start: 1rem;
        padding-inline-end: 2rem;
        padding-top: 0.75rem;
        padding-bottom: 1.75rem;
        color: #ffffff;
        align-items: start;
        border-radius: 0.375rem;;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
        text-align: start;
        width: auto;
      }

       div[id^="toast-"][data-status="warning"]:not([id$="-title"]):not([id$="-description"]) {
        color-scheme: light;
        font-feature-settings: "kern";
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        line-height: 1.5;
        pointer-events: auto;
        border-width: 0;
        border-style: solid;
        box-sizing: border-box;
        word-wrap: break-word;
        border-color: #E2E8F0;;
        display: flex;
        position: relative;
        overflow: hidden;
        background:rgb(234, 137, 40);
        padding-inline-start: 1rem;
        padding-inline-end: 2rem;
        padding-top: 0.75rem;
        padding-bottom: 1.75rem;
        color: #ffffff;
        align-items: start;
        border-radius: 0.375rem;;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
        text-align: start;
        width: auto;
      }

      div[id^="toast-"][data-status="error"]:not([id$="-title"]):not([id$="-description"]) {
        color-scheme: light;
        font-feature-settings: "kern";
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        line-height: 1.5;
        pointer-events: auto;
        border-width: 0;
        border-style: solid;
        box-sizing: border-box;
        word-wrap: break-word;
        border-color: #E2E8F0;;
        display: flex;
        position: relative;
        overflow: hidden;
        background:rgb(234, 137, 40);
        padding-inline-start: 1rem;
        padding-inline-end: 2rem;
        padding-top: 0.75rem;
        padding-bottom: 1.75rem;
        color: #ffffff;
        align-items: start;
        border-radius: 0.375rem;;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
        text-align: start;
        width: auto;
      }

       div[id^="toast-"][data-status="info"]:not([id$="-title"]):not([id$="-description"]) {
        color-scheme: light;
        font-feature-settings: "kern";
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        line-height: 1.5;
        pointer-events: auto;
        border-width: 0;
        border-style: solid;
        box-sizing: border-box;
        word-wrap: break-word;
        border-color: #E2E8F0;;
        display: flex;
        position: relative;
        overflow: hidden;
        background:rgb(234, 137, 40);
        padding-inline-start: 1rem;
        padding-inline-end: 2rem;
        padding-top: 0.75rem;
        padding-bottom: 1.75rem;
        color: #ffffff;
        align-items: start;
        border-radius: 0.375rem;;
        box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05);
        text-align: start;
        width: auto;
      }
      `;