import { Box, Switch, Flex } from "@chakra-ui/react";
import { Header } from "./components/widget/Header";
import { Steps } from "./components/widget/Steps";
import { ShoppingCartProvider } from "./providers/ShoppingCartProvider";
import { ProductProvider } from "./providers/ProductProvider";
import { ModalAddress2 } from "./components/modal/ModalAddress2";
import { HowToWork } from "./components/modal/HowToWork";
import { useWidget } from "./providers/WidgetProvider";
import { AddressProvider } from "./providers/AddressProvider";
import { AuthService } from "./services/AuthService";
import { SentryApi } from "./helper/Sentry";
import { dataConfig } from "./helper/Config";
import { AskForAddress } from "./components/modal/AskForAddress";
import { ClarityModule } from "./helper/Clarity";

function App(): React.JSX.Element {
  const widget = useWidget()

  if (widget == null) return(<></>);

  /** Send metrics to Clarity and sentry.
   */
  const sendMetrics = (): void => {
      ClarityModule.activate();
      SentryApi.captureMessage(`${dataConfig.metricsPrefix}: Activated`);
  }

  /** Handler the widget, change the state of the widget.
  */
  const handlerWidget = async (): Promise<void> => {
      if (widget.isOn) {
          widget.closeWidget();
          return;
      }
      if (!AuthService.validateSession()) {
          widget.openCloseModalAskForAddress(true);
      } else {
        widget.openWidget();
      }
      sendMetrics();
  }

  return (
    <Box
      maxW="500px"
      minW="250px"
      minH="75px"
      w="100%"
      mt="10px"
      rounded="10px"
      pb="10px"
    >
      <>
        <Flex>
          <Switch
            size="lg"
            pt={5}
            pl={2}
            pr={2}
            colorScheme="purple"
            onChange={handlerWidget}
            isChecked={widget.isOn ?? false}
          />
          <Header />
        </Flex>
        <ShoppingCartProvider>
          <ProductProvider>
            <AddressProvider>
              <>
                <Steps />
                {
                  widget.isOpenModalAskForAddress &&
                  <AskForAddress comission={widget?.configData?.commission.toLocaleString('es-co')}/>
                }
                {
                  widget.isOpenModalAdress && 
                  <ModalAddress2  />
                }
              </>
            </AddressProvider>
          </ProductProvider>
        </ShoppingCartProvider>
      </>
     
      {widget.isOpenModalHowTowork && 
        <HowToWork />
      }
    </Box>
  );
}

export default App;
