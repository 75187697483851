import React from "react";
import { DataConfigModel } from "../models/DataConfigModel";

export interface WidgetContextType {
    isOn: boolean;
    step: string;
    isOpenModalAdress: boolean
    isOpenModalHowTowork: boolean;
    isOpenModalAskForAddress: boolean;
    configData: DataConfigModel | null;
    shadowHostRef: React.RefObject<HTMLDivElement>;
    openCloseModalAddress: (state: boolean) => void;
    openCloseModalHowTowork: (state: boolean) => void;
    openCloseModalAskForAddress: (state: boolean) => void;
    closeWidget: () => Promise<void>;
    openWidget: () => Promise<void>;
    handlerSteps: (nextStep: 'product' | 'phone' | 'address' | 'data-customer' | 'finish' | 'howToWork') => void;
    setIsOn: (value: React.SetStateAction<boolean>) => void;
}
export const WidgetContext = React.createContext<WidgetContextType | null>(null);
