declare global {
  interface Window {
    mapInit: google.maps.Map;
  }
}

import { useState} from "react";
import { useAddress } from "../providers/AddressProvider";
import { Data } from "../helper/Data";
//import { useWidget } from "../providers/WidgetProvider";

export const useModaladdress2 = () => {

  const [buttonSaveActive, setButtonSaveActive] = useState<boolean>(false);
  //const [showMap, setShowMap] = useState<boolean>(false);
  const [country, setCountry] = useState('');
  const [locality, setLocality] = useState('');
  const [formatAddress, setFormatAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('')
  const addr = useAddress();
  //const widget = useWidget()

  const getConuntryCode = (): Array<string> => {
    switch (addr?.address?.country_name) {
      case "Colombia":
        return ["CO"];
      case "Chile":
        return ["CL"];
      default:
        //Cuando no está definido la dirección del cliente.
        const hostId = Data.getHostId();
        switch (hostId) {
          case '546556':
            return ["CO"];
          case '589462':
            return ["CL"];
          default:
            return ["CL","CO"];
        }
    }
  }

  const iniMap = (mapRef?:any, autoCompleteRef?:any, additionalInfoRef?:any,  latitude=4.60971, longitude=-74.08175) =>{
      const vzlaCoords = { lat: latitude, lng: longitude };
      //const mpDiv = widget?.shadowHostRef.current?.querySelector("#map");
      const mpDiv = mapRef;
      if(!mpDiv) return;
      window.mapInit = new google.maps.Map(mpDiv  as HTMLElement, {
        center: vzlaCoords,
        zoom: 16,
        panControl: false,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        //overviewMapControl: false,
        rotateControl: false
      });
      let marker = new google.maps.Marker({
        position: vzlaCoords,
        map: window.mapInit,
        draggable: true
      });
      marker.addListener('click', () => {
        if(!marker) return;
        const getmap = marker.getMap();
        getmap?.setZoom(16)
        console.log(marker.getPosition());
        //getmap?.setCenter(marker.getPosition())
      });
      marker.addListener('dragend', () => {
        marker.getMap()?.setZoom(16);
        console.log(marker.getMap());
        //marker.getMap()?.center(marker.getPosition());
        getAddressDirectionByCoords(marker, autoCompleteRef, additionalInfoRef); 
      });
      initAutocomplete(window.mapInit, autoCompleteRef, additionalInfoRef, marker);
      validButton();
      return marker;
  }

  const initAutocomplete = (map1:any, autoCompleteRef?:any, additionalInfoRef?:any, marker?:any) => {
    let autocomplete:any;
    //const input:HTMLInputElement  = document.getElementById("place_input") as HTMLInputElement ;
    const input:HTMLInputElement  = autoCompleteRef as HTMLInputElement ;
    if (!input) return;
    const options = {
     /*  bounds: defaultBounds, */
      componentRestrictions: { country: getConuntryCode() },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
      /* types: ["establishment"], */
    };
    autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener('place_changed', function () {
      const place = autocomplete.getPlace();
      map1.setCenter(place.geometry.location);
      map1.setZoom(18);
      marker.setPosition(place.geometry.location);
      getAddressDirectionByCoords(marker, autoCompleteRef, additionalInfoRef);
    })
  }

  const getAddressDirectionByCoords = (marker:any, autoCompleteRef?:any, additionalInfoRef?:any) => {
    const geocoder = new google.maps.Geocoder()
    //console.log(marker);
    geocoder.geocode({ location: marker.getPosition() })
      .then(({ results }) => {
        const { address_components, formatted_address, /* geometry, place_id, plus_code */ } = results[0];

        //const input:HTMLInputElement = document.getElementById("place_input") as HTMLInputElement ;
        const input:HTMLInputElement = autoCompleteRef as HTMLInputElement ;
        input.value = formatted_address;
        const lat =  marker.getPosition().lat();
        const lng = marker.getPosition().lng()
    
        setFormatAddress(formatted_address);
        //const addInfo = document.getElementById('additional-info-dato') as HTMLInputElement;
        const addInfo = additionalInfoRef as HTMLInputElement;
        setAdditionalInfo(addInfo.value);
        setLatitude(lat);
        setLongitude(lng);

        address_components.map((item) => {
          switch (item.types[0]) {
            case 'country':
              setCountry(item.long_name);
                //console.log('contry: -->'+item.long_name)
              break;
              case 'locality':
                setLocality(item.long_name);
                //console.log('locality: -->'+item.long_name)
              break;
            default:
              //console.log('otros --> '+ item.types[0]+'= '+item.long_name)
              break;
          }
        });
        validButton();
      }
      ).catch((e) => console.log("Geocoder failed due to: " + e));
  }

  const validButton = () => {
    setButtonSaveActive(true); //temporalmente
  }

  const handlerAdditionalInformation = (event:any) => {
    setAdditionalInfo(event.target.value);
  }

  const styleInputMap = {
    border: "2px solid rgb(217, 191, 237)",
    borderRadius: "10px",
    width: "260px",
    height: "35px",
    margin: "10px auto",
    paddingLeft: "15px",
}

  return {buttonSaveActive, styleInputMap, country, locality, formatAddress, latitude, longitude, additionalInfo, iniMap, handlerAdditionalInformation}
}
